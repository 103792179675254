import { combineReducers } from '@reduxjs/toolkit';
import { offerReducer } from 'store/slices/admin/offer';
import { inventoryReducer } from './slices/Trade/TradeState';
import { adminReducer } from './slices/admin/admin';
import { apiSlice } from './slices/api/api';

const combinedReducers = combineReducers({
    inventory: inventoryReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    admin: adminReducer,
    offer: offerReducer,
});

export default combinedReducers;

