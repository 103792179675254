import Loading from 'components/common/loading/Loading';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetUserQuery } from 'store/slices/api/api';

const PrivateRoute = () => {
    const { data: user, isLoading } = useGetUserQuery(null);

    if (isLoading) {
        return <Loading />;
    }

    return user && user.loggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
