import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { InventoryItem } from 'types/InventoryItem';
import { OfferWithMessages, SendMessagePayload, SendOfferPayload, UserData } from 'types/offer';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: config.uri_api, credentials: 'include' }),
    tagTypes: ['Offer'],
    endpoints: (builder) => ({
        getUser: builder.query<UserData, null>({
            query: () => '/auth/user',
        }),
        getInventoryData: builder.query({
            query: () => '/steam/inventory',
        }),
        getOffers: builder.query({
            query: (params: {managerId: string, statusId: string}) => `/admin/offers?managerId=${params.managerId}&statusId=${params.statusId}`,
        }),
        getOffer: builder.query<OfferWithMessages, number>({
            query: (offerId) => {
                return {
                    url: `/admin/offer?offerId=${offerId}`,
                };
            },
            providesTags: ['Offer'],
        }),
        getLastItems: builder.query<InventoryItem[], null>({
            query : () => {
                return {
                    url: `/main/last`
                };
            }
        }),
        sendMessage: builder.mutation({
            query: (messageForm: SendMessagePayload) => ({
                url: '/admin/offer/send',
                method: 'POST',
                body: messageForm,
            }),
            invalidatesTags: ['Offer'],
        }),
        sendOffer: builder.mutation({
            query: (offerForm: SendOfferPayload) => ({
                url: '/offer/send',
                method: 'POST',
                body: offerForm,
            })
        }),
        getManagers: builder.query({
            query: () => '/admin/managers'
        }),
        updateOffer: builder.mutation({
            query: (setManagerData: {offerId: number, managerId: number, statusId: number}) => ({
                url: '/admin/offer/update',
                method: 'POST',
                body: setManagerData,
            }),
            invalidatesTags: ['Offer'],
        }),
    }),
});

export const { 
    useGetUserQuery, 
    useGetInventoryDataQuery, 
    useGetOffersQuery, 
    useGetOfferQuery, 
    useSendMessageMutation, 
    useSendOfferMutation, 
    useGetManagersQuery,
    useUpdateOfferMutation,
    useGetLastItemsQuery,
} = apiSlice;
