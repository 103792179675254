import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Offer } from 'types/offer';

export interface OfferState {
    tabIndex: number,
    currentOffer: Offer | undefined;
    messageText: string,
}

const initialState: OfferState = {
    tabIndex: 0,
    currentOffer: undefined,
    messageText: ''
};

const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        setCurrentOfferTab(state, action: PayloadAction<number>) {
            state.tabIndex = action.payload;
        },
        setCurrentOffer(state, action: PayloadAction<Offer | undefined>) {
            state.currentOffer = action.payload;
        },
        setMessageText(state, action: PayloadAction<string>) {
            state.messageText = action.payload;
        }
    },
});

export const offerReducer = offerSlice.reducer;
export const { setCurrentOffer, setCurrentOfferTab, setMessageText } = offerSlice.actions;
