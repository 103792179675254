import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AdminState {
    currentTabIndex: number;
}

const initialState: AdminState = {
    currentTabIndex: 0,
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setCurrentTab(state, action: PayloadAction<number>) {
            state.currentTabIndex = action.payload;
        },
    },
});

export const adminReducer = adminSlice.reducer;
export const { setCurrentTab} = adminSlice.actions;
