const config = {
    env: process.env.REACT_APP_ENV || 'dev',
    uri_api: process.env.REACT_APP_API_BASE || 'http://localhost:3001',
};

const api_endpoints = {
    login: `${config.uri_api}/auth/steam`,
    logout: `${config.uri_api}/auth/logout`,
    inventory: `${config.uri_api}/steam/inventory`,
};

export { config, api_endpoints };
