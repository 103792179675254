import 'App.css';
import Loading from 'components/common/loading/Loading';
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import { StoreProvider } from 'store/StoreProvider';
import CustomThemeProvider from 'theme/CustomThemeProvider';

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <StoreProvider>
                <Router>
                    <CustomThemeProvider>
                        <AppRoutes />
                    </CustomThemeProvider>
                </Router>
            </StoreProvider>
        </Suspense>
    );
}

export default App;
