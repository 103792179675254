import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

export const commonColors = {
    white: '#fff',
    black: '#000',
    clear: '00000000',
    primary: '#0F8DEB',
    primaryLighter: '#349fef',
    contrast: '#FFB22F',
    blueWhiteText: "#8E9BA8",
    lighterBackground: '#101e31',
};

const CustomThemeProvider = (props: PropsWithChildren<Record<string, unknown>>) => {
    const darkMode = false;
    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            primary: {
                main: '#0F8DEB',
                contrastText: '#000000',
            },
            secondary: {
                main: '#000000',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF',
            },
            error: {
                main: '#F95656'
            },
            common: {
                white: '#EEEEEE',
                black: '#111111'
            }
        },
        background: '#04080d',
        font: '#EEEEEE',
        commonColors,
    });
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

declare module '@mui/material/styles' {
    interface Theme {
        background: string;
        font: string;
        commonColors: { [key: string]: string };
    }

    interface ThemeOptions {
        background: string;
        font: string;
        commonColors: { [key: string]: string };
    }
}

export default CustomThemeProvider;
