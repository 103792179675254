import { configureStore } from '@reduxjs/toolkit';
import combinedReducers from './root-reducer';
import { apiSlice } from './slices/api/api';

const store = configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
