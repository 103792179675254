import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InventoryItem } from 'types/InventoryItem';

export interface InventoryState {
    selectedItems: InventoryItem[];
    currentStep: number;
}

const initialState: InventoryState = {
    selectedItems: [],
    currentStep: 0,
};

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        addSelectedItem(state, action: PayloadAction<InventoryItem>) {
            if (state.selectedItems.some((x) => x.id == action.payload.id)) return;
            state.selectedItems.push(action.payload);
        },
        addSelectedItems(state, action:PayloadAction<InventoryItem[]>) {
            state.selectedItems = action.payload;
        },
        clearSelection(state) {
            state.selectedItems = [];
        },
        removeItem(state, action: PayloadAction<InventoryItem>) {
            if (state.selectedItems.some((x) => x.id == action.payload.id)) {
                state.selectedItems = state.selectedItems.filter((x) => x.id !== action.payload.id);
            }
        },
        setCurrentStep(state, action: PayloadAction<number>) {
            state.currentStep = action.payload;
        }
    },
});

export const inventoryReducer = inventorySlice.reducer;
export const { addSelectedItem, removeItem, setCurrentStep, addSelectedItems, clearSelection  } = inventorySlice.actions;
